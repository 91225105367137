import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomeAbout = () => {

    return (
        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" > overview  <span>//</span></div>
                <div className="container">
                <div className="row">
                   
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                            <h2> Overview  </h2>
                            <p>PragICTS offers software development services to drive digitization initiatives of the IT landscape to enhance organization throughput, performance, capabilities, and differentiations to be ahead of the curve. This is further strengthened by our services offering relating to ITOps <a class="codered" href="https://itops.pragicts.com" target="_blank"> (https://itops.pragicts.com)</a> and CloudOps<a class="codered" href="https://cloudops.pragicts.com" target="_blank">  (https://cloudops.pragicts.com)</a> to provide a compelling, cohesive and all-inclusive value proposition all under one roof.</p>
                         
                            <p>The capacity of this offering is cemented by a referenceable client base and project portfolio to provide that assurance for engagements with confidence with PragICTS.</p>
                            <div className="features-box-container fl-wrap">
                                <div className="row">
                                  
                                <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-infrastructure-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://itops.pragicts.com/"><h3>ITOps</h3></a>
                                        <p>Implementation, management, and support of ICT Infrastructure. On-site, remote, hybrid, and on-call service models.</p>
                                        <p><a className="prag-red" target="_blank" href="https://itops.pragicts.com/">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-cloud-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://cloudops.pragicts.com/"><h3>CloudOps</h3></a>
                                        <p>Provisioning, management, and support of cloud platforms. Amazon, Microsoft, Google, and other third parties.</p>
                                        <p><a className="prag-red" target="_blank" href="https://cloudops.pragicts.com/">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-security-assurance-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://cybersecops.pragicts.com/"><h3>CyberSecOps</h3></a>
                                        <p>Comprehensive Cyber Security Operation Services for oraginzation of all scales.</p>
                                        <p><a className="prag-red" target="_blank" href="https://cybersecops.pragicts.com/">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-expertise-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://digitaloutreach.pragicts.com/"><h3>Digital Outreach</h3></a>
                                        <p>Holistic and cohesive digital outreach services designed to expand the digital reach of IT to bring into fold areas and domains that have not been subjected to effective capitalization by deploying sensors, IoTs, various extensibility devices, and software.</p>
                                        <p><a className="prag-red" target="_blank" href="https://digitaloutreach.pragicts.com/">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-efficiency-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://automation.pragicts.com/"><h3>Automation</h3></a>
                                        <p>
                                        IT Automation Services for the effective automation of IT Infrastructure including cloud infrastructure and IoT to minimize human error, effort, latency and enhance the optimal availability and performance of IT infrastructure</p>
                                        <p><a className="prag-red" target="_blank" href="https://automation.pragicts.com/">More Details ...</a></p>
                                    </div>
                                </div>
                            </div>
               
                       
                       {/*      <div className="features-box-container fl-wrap">
                                <div className="row">
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-software-icon"></i>
                                        </div>
                                        <Link to="/service/software-development"><h3>Software</h3></Link>
                                        <p>Software development and support services. Web (including websites), Mobile and Rich Client Applications.</p>
                                        <p><Link to="/service/software-development">More Details ...</Link></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-infrastructure-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://infrastructure.pragicts.com"><h3>Infrastructure</h3></a>
                                        <p>Implementation, management, and support of ICT Infrastructure.  On-site, remote, hybrid, and on-call service models.</p>
                                        <p><a target="_blank" href="https://infrastructure.pragicts.com">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-cloud-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://cloud.pragicts.com"><h3>Cloud</h3></a>
                                        <p>Provisioning, management, and support of cloud platforms. Amazon, Microsoft, Google, and other third parties.</p>
                                        <p><a target="_blank" href="https://cloud.pragicts.com">More Details ...</a></p>
                                    </div>
                                </div>
                            </div>  */}
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                </div>
            </div>
           {/*     <div className="container">
                <div className="row">
                    <div className="col-md-8">

                    <div className="col-md-4">  <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="1504388"></div></div>
                    <div className="col-md-4">    <a href="https://www.designrush.com/agency/profile/pragicts" target="_blank"><StaticImage src="../images/home/designrush-accredited-agency-pragicts.png" className="highlights" ></StaticImage></a></div>

                    </div>
                </div>
            </div>*/}
            <div className="bg-parallax-module" data-position-top="90"  data-position-left="25" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeAbout
