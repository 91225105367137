import React from 'react'
import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'

import eset from '../images/technologies/eset.png'

import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'

import hp from '../images/technologies/hp.png'
import dell from '../images/technologies/dell.png'
import lenovo from '../images/technologies/lenovo.png'

import datwyler from '../images/technologies/datwyler.png'
import extreme from '../images/technologies/extreme.png'
import azure from '../images/technologies/azure.png'
import splunk from '../images/technologies/splunk.png'
import schneider from '../images/technologies/schneider.png'

import google from '../images/technologies/google.png'
import microsoft from '../images/technologies/microsoft_logo.png'
import juniper from '../images/technologies/juniper.png'
import f5 from '../images/technologies/f5.png'
import tplink from '../images/technologies/tplink.png'
import acronis from '../images/technologies/acronis.png'
import dellemc from '../images/technologies/dellemc.png'
import nessus from '../images/technologies/nessus.png'
import qualys from '../images/technologies/qualys.png'
import timik from '../images/technologies/timik.png'
import rapid7 from '../images/technologies/rapid7.png'
import milesight from '../images/technologies/milesight.png'

import vutlan from '../images/technologies/vutlan.png'
import netskope from '../images/technologies/netskope.png'
import versa from '../images/technologies/versa.png'
import dji from '../images/technologies/dji.png' 
import qnap from '../images/technologies/qnap.png'
import ransnet from '../images/technologies/ransnet.png'
import cortexxdr from '../images/technologies/cortexxdr.png'
const clients = [
    {
        name: 'synology',
        logo: synology,
        url: 'https://www.synology.com/en-uk'
    },
    {
        name: 'cisco',
        logo: cisco,
        url: 'https://www.cisco.com/'
    },
    {
        name: 'fortinet',
        logo: fortinet,
        url: 'https://www.fortinet.com/'
    },
   
    {
        name: 'eset',
        logo: eset,
        url: 'https://www.eset.com/lk/'
    },
   
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: 'https://www.bitdefender.com/'
    },
   
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: 'https://www.checkpoint.com/'
    },
 
    {
        name: 'paloalto',
        logo: paloalto,
        url: 'https://www.paloaltonetworks.com/'
    },
 
    {
        name: 'lenovo',
        logo: lenovo,
        url: 'https://www.lenovo.com/'
    },
    {
        name: 'dell',
        logo: dell,
        url: 'https://www.dell.com/'
    },
    {
        name: 'hp',
        logo: hp,
        url: 'https://www.hp.com/'
    },
    {
        name: 'Datwyler',
        logo: datwyler,
        url: 'https://datwyler.com/'
    },
    {
        name: 'Extreme',
        logo: extreme,
        url: 'https://www.extremenetworks.com/'
    },
    {
        name: 'Azure',
        logo: azure,
        url: 'https://azure.microsoft.com/en-us'
    },
    {
        name: 'Splunk',
        logo: splunk,
        url: 'https://www.splunk.com/'
    },
    {
        name: 'Schneider',
        logo: schneider,
        url: 'https://www.se.com/lk/en/'
    },
    {
        name: 'Google',
        logo: google,
        url: 'https://www.google.com/'
    },
    {
        name: 'Microsoft',
        logo: microsoft,
        url: 'https://www.microsoft.com/'
    },


 

    {
        name: 'Juniper',
        logo: juniper,
        url: 'https://www.juniper.net/'
    },
    {
        name: 'F5',
        logo: f5,
        url: 'https://www.f5.com/'
    },
    {
        name: 'Tplink',
        logo: tplink,
        url: 'https://www.tplink.com/'
    },

    {
        name: 'Acronis',
        logo: acronis,
        url: 'http://www.acronis.com/'
    },

    {
        name: 'Dell EMC',
        logo: dellemc,
        url: 'https://www.dell.com/en-sg/blog/tags/dell-emc/'
    },

    {
        name: 'Nessus',
        logo: nessus,
        url: 'https://www.tenable.com/products/nessus'
    },
    {
        name: 'Qualys',
        logo: qualys,
        url: 'https://www.qualys.com/'
    },
    {
        name: 'Timik',
        logo: timik,
        url: 'https://timikenclosures.com/Timik/'
    },
    {
        name: 'Rapid7',
        logo: rapid7,
        url: 'https://www.rapid7.com/'
    },
    {
        name: 'Milesight',
        logo: milesight,
        url: 'www.milesight.com'
    },

    {
        name: 'Vutlan',
        logo: vutlan,
        url: 'https://vutlan.com/'
    },
    {
        name: 'Netskope',
        logo: netskope,
        url: 'https://www.netskope.com/'
    },
    {
        name: 'Versa',
        logo: versa,
        url: 'https://versa-networks.com/'
    },
    {
        name: 'Dji',
        logo: dji,
        url: 'https://www.dji.com/'
    },
    {
        name: 'Qnap',
        logo: qnap,
        url: 'https://www.qnap.com/'
    },
    {
        name: 'Ransnet',
        logo: ransnet,
        url: 'https://ransnet.com/'
    },
    {
        name: 'cortexXDR',
        logo: cortexxdr,
        url: 'https://www.paloaltonetworks.com/cortex/cortex-xdr'
    },
]

const Hometech = () => {
    return (
        <section  data-scrollax-parent="true" id="partnership">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Partnership<span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Partnership</h3>
                    <h2>Technology  <span>Partnership</span></h2>
                 
                </div>
            </div>
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Hometech
